import classNames from "classnames";
import Image from "next/image";
import Link from "next/link";
import { useTranslations } from "next-intl";
import { useEffect, useMemo } from "react";

import { useAuthContext } from "~/contexts/auth";
import { useBreakpoint } from "~/contexts/breakpoint";
import { useWishlist } from "~/contexts/wishlist";
import { useCartProduct } from "~/hooks/use-cart";
import Constants from "~/utils/constants";
import imageUtils from "~/utils/image-utils";
import productUtils from "~/utils/product-utils";
import TrackingUtils from "~/utils/tracking-utils";

import Icon from "../common/icon";
import { ProductActionOutcome } from "../common/product-actions";
import QuantitySelector from "../common/quantity-selector";
import SimpleQuantitySelector from "../common/simple-quantity-selector";
import ProductInfoGift from "../product/product-info-gift";
import CartProductPrice from "./cart-product-price";
import styles from "./cart-product-tile.module.scss";
import DiscountProductTile from "./discount-product-tile";

export enum CartProductTileContext {
  default = "cart",
  oosCartModal = "oosCartModal",
  orderHistoryDetail = "orderHistoryDetail",
}

type Props = {
  line: EVA.Core.OrderLineDto;
  disableCartActions?: boolean;
  index?: number;
  outOfStockDelivery?: boolean;
  outOfStockPickup?: boolean;
  onRemove?: (
    prodId: number,
    productName: string,
    index: number,
    quantity: number,
    outOfStockDelivery?: boolean
  ) => void;
  movedToWishlist?: (prodId: number, productName: string, index: number, quantity: number) => void;
  className?: string;
  disableCartQty?: boolean;
  quantity?: number;
  toggleQuantity?: (removing: boolean) => void;
  disableRemove?: boolean;
  disableAdd?: boolean;
  hideOrderQty?: boolean;
  onOpenLogin?: () => void;
  giftCardData?: EVA.Core.GiftCard;
  physicalGiftCardRequirement?: EVA.Core.ProductRequirementLineModel[];
  context?: CartProductTileContext;
  trackingScope?: string;
  showNetPriceWithoutPromo?: boolean;
  rootProductDetails?: any;
  minicart?: boolean;
};

export default function CartProductTile(props: Props) {
  const t = useTranslations();
  const breakpoint = useBreakpoint();
  const { isAuthenticated } = useAuthContext();
  const { saveForLater, refetch, savedForLaterList } = useWishlist();
  const { outOfStockDelivery = false, outOfStockPickup = false, context = CartProductTileContext.default } = props;

  function _trackCartChange(trackingScope: string, action: ProductActionOutcome, line: EVA.Core.OrderLineDto) {
    const trackPayload = TrackingUtils.mapBasketAction(
      "cart",
      action,
      trackingScope,
      TrackingUtils.mapEcomCartItem(line)
    );
    trackPayload && TrackingUtils.track(trackPayload);
  }

  const existInSaveForLater = savedForLaterList?.Products?.find((el) => el.product_id === props.line.ProductID);
  // The product is a digital giftcard: TODO => USE cart-utils
  const isGift = props.line.Product?.Properties?.product_types.includes(Constants.DIGITAL_GIFTCARD_PRODUCT_TYPE);

  const trackingInfo = useMemo(() => {
    if (!props.trackingScope) {
      return undefined;
    }
    return {
      context: props.trackingScope,
      info: TrackingUtils.mapEcomCartItem(props.line, props.rootProductDetails),
    };
  }, [props.line, props.rootProductDetails, props.trackingScope]);

  const { removeAllFromCart, addToCart, removeFromCart } = useCartProduct(
    props.line.ProductID!,
    isGift ? props.line.ID : undefined,
    trackingInfo
  );

  const product = props.line.Product!.Properties;
  const primaryImage = product?.product_media?.primary_image;

  const color = props.line.ProductVariation?.["color"];
  const goccia = productUtils.getProductDroplet(product?.product_media?.media);
  const productUrl = props.line.Product?.Properties?.slug
    ? props.line.Product?.Properties?.slug + "-" + props.line.ProductID
    : undefined;

  const saveForLaterHandler = async () => {
    if (isAuthenticated) {
      if (props.line.ProductID) {
        await removeAllFromCart.mutateAsync();
        await saveForLater(props.line.ProductID).then(() => refetch());
        if (props.movedToWishlist && props.index != undefined) {
          props.movedToWishlist(props.line.ProductID, product.display_value, props.index, props.line.QuantityOpen);
        }
      }
    } else {
      if (props.onOpenLogin) {
        props.onOpenLogin();
      }
    }
  };

  const removeHandler = async () => {
    if (props.line.ProductID) {
      await removeAllFromCart.mutateAsync();
      if (props.onRemove && props.index !== undefined && !isGift) {
        props.onRemove(
          props.line.ProductID,
          product.display_value,
          props.index,
          props.line.QuantityOpen,
          outOfStockDelivery
        );
      }
    }
  };

  useEffect(() => {
    if (addToCart.isSuccess && props.trackingScope) {
      _trackCartChange(props.trackingScope, "added", props.line);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToCart.isSuccess]);

  useEffect(() => {
    if (removeFromCart.isSuccess && props.trackingScope) {
      _trackCartChange(props.trackingScope, "removed", props.line);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeFromCart.isSuccess]);

  const productLinkContent = (
    <>
      <Image
        src={props.giftCardData?.Data?.Theme.ImageUrl || primaryImage?.url || imageUtils.transparentPixel}
        alt={product?.display_value || ""}
        width={80}
        height={80}
      />
      {outOfStockDelivery &&
        (context === CartProductTileContext.oosCartModal || context === CartProductTileContext.default) && (
          <div className={styles.outOfStockIconContainer}>
            <Icon name="non-shoppable-country" width={24} height={24} />
          </div>
        )}
    </>
  );

  return (
    <div className={styles.wrapperContainer}>
      <div
        className={classNames(
          styles.container,
          {
            [styles.cancelledItem]: props.line.IsCancelled,
            [styles.outOfStockDelivery]:
              outOfStockDelivery &&
              (context === CartProductTileContext.oosCartModal || context === CartProductTileContext.default),
          },
          props.className
        )}
      >
        {productUrl ? (
          <Link href={`/p/${productUrl}`} className={styles.productLink}>
            {productLinkContent}
          </Link>
        ) : (
          <div className={styles.productLink}>{productLinkContent}</div>
        )}
        <div className={styles.infoContainer}>
          <p className={styles.productName}>{product?.display_value || props.line.Product?.PrimitiveName}</p>
          {props.giftCardData || (props.physicalGiftCardRequirement || []).length > 0 ? (
            <ProductInfoGift
              giftCardData={props.giftCardData}
              physicalGiftCardRequirement={props.physicalGiftCardRequirement}
            />
          ) : color && !outOfStockDelivery ? (
            <div className={classNames(styles.colorContainer, { [styles.outOfStockDelivery]: outOfStockDelivery })}>
              <Image
                src={goccia?.url || imageUtils.transparentPixel}
                alt={color}
                width={12}
                height={12}
                className={classNames(styles.colorImage, { [styles.outOfStockDelivery]: outOfStockDelivery })}
                loader={imageUtils.evaImageLoader}
              />
              <p className={styles.colorName}>{color}</p>
            </div>
          ) : (
            <div className={styles.colorPlaceholder} />
          )}
          <div className={styles.bottomContainer}>
            <div className={styles.actionsContainer}>
              {context === CartProductTileContext.orderHistoryDetail && !outOfStockDelivery
                ? !props.hideOrderQty && (
                    <p className={styles.colorName}>{t("generic.quantity", { quantity: props.line.QuantityOpen })}</p>
                  )
                : !props.line.CustomOrderLineType &&
                  !props.disableCartQty && (
                    <>
                      {!isGift &&
                        !outOfStockDelivery &&
                        (props.toggleQuantity ? (
                          <SimpleQuantitySelector
                            quantity={props.quantity!}
                            onClick={props.toggleQuantity}
                            disableAdd={props.disableAdd}
                            disableRemove={props.disableRemove}
                          />
                        ) : (
                          <QuantitySelector
                            productID={props.line.ProductID!}
                            lineID={isGift ? props.line.ID : undefined}
                            disableAdd={props.disableAdd}
                            disableRemove={props.disableRemove}
                            maxQuantity={props.line.Product?.Properties?.ecomm_max_items_per_order}
                            actionContext={props.minicart ? "minicart" : "cart"}
                            trackingData={trackingInfo?.info}
                          />
                        ))}
                      {!props.disableCartActions && breakpoint === "desktop" && (
                        <button
                          className={styles.button}
                          onClick={() => removeHandler()}
                          disabled={removeAllFromCart.isLoading}
                        >
                          {t("generic.remove")}
                        </button>
                      )}
                      {!props.disableCartActions && breakpoint === "desktop" && !existInSaveForLater && !isGift && (
                        <button
                          className={styles.button}
                          onClick={() => saveForLaterHandler()}
                          disabled={removeAllFromCart.isLoading}
                        >
                          {t("generic.save_for_later")}
                        </button>
                      )}
                    </>
                  )}
            </div>
            <CartProductPrice showNetPriceWithoutPromo={props.showNetPriceWithoutPromo} line={props.line} />
          </div>
          {!props.showNetPriceWithoutPromo && props.line.Discounts.length != 0 && (
            <DiscountProductTile line={props.line} showNetPriceWithoutPromo={props.showNetPriceWithoutPromo} />
          )}
          {!props.disableCartActions && !props.line.CustomOrderLineType && breakpoint === "mobile" && (
            <div className={styles.mobileActions}>
              <button className={styles.button} onClick={() => removeHandler()} disabled={removeAllFromCart.isLoading}>
                {t("generic.remove")}
              </button>
              {!existInSaveForLater && !isGift && (
                <button
                  className={styles.button}
                  onClick={() => saveForLaterHandler()}
                  disabled={removeAllFromCart.isLoading}
                >
                  {t("generic.save_for_later")}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      {props.showNetPriceWithoutPromo && props.line.Discounts.length != 0 && (
        <DiscountProductTile line={props.line} showNetPriceWithoutPromo={props.showNetPriceWithoutPromo} />
      )}
    </div>
  );
}
